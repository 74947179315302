export const GA_EVENTS = {
    CAR_CARD_HOVER_ENDED_TIME: "hover_ended_time",
    CAR_CARD_HOVER_CARDS_SEEN: "hover_ended_image",

    CAR_CARD_HOVER_ENDED_TIME_DATA: {
        event: "desktop_clp",
        eventCategory: "car_card_hover",
        eventAction: "hover_ended_time"
    },
    CAR_CARD_HOVER_CARDS_SEEN_DATA: {
        event: "desktop_clp",
        eventCategory: "car_card_hover",
        eventAction: "hover_ended_image"
    },
    LOCATION_LINK_CLICKED: {
        eventAction: "location_link_clicked",
        eventCategory: "Cars24_listing_page",
        event: "custom_event"
    },
    DESKTOP_CARD_GALLERY_SCROLLED: {
        event_category: "Cars24_listing_page",
        event_action: "image_number",
        event: "desktop_card_gallery_scrolled"
    },
    DESKTOP_CARD_GALLERY_CLICKED: {
        event_category: "Cars24_listing_page",
        event_action: "app_id",
        event: "desktop_card_gallery_clicked"
    }

};

export const AMPLITUDE_EVENT = {
    CAR_CARD_HOVER_ENDED: "car image hover ended"
};
