import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import IconBack from "../images/icons/back-icon.svg";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";

const CLICK_DIRECTION = {
    PREV: "prev",
    NEXT: "next"
};

const CustomReactSlickNavigation = ({ handleClickCallback, totalSlides = 0, currentIndex = 0 }) => {
    const handleClick = async (e, direction) => {
        e.preventDefault();
        e.stopPropagation();
        await yieldToMain();
        handleClickCallback(direction);
    };
    return (
        <React.Fragment>
            {currentIndex > 0 && (
                <div styleName="styles.leftClickArea" onClick={(e) => handleClick(e, CLICK_DIRECTION.PREV)}>
                    <div styleName="styles.leftIconWrapper">
                        <img src={IconBack} alt="Back" />
                    </div>
                </div>
            )}
            {(currentIndex < totalSlides - 1) && (
                <div styleName="styles.rightClickArea" onClick={(e) => handleClick(e, CLICK_DIRECTION.NEXT)}>
                    <div styleName="styles.rightIconWrapper">
                        <img src={IconBack} alt="Back" />
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

CustomReactSlickNavigation.propTypes = {
    handleClickCallback: PropTypes.func,
    totalSlides: PropTypes.number,
    currentIndex: PropTypes.number
};

export default CustomReactSlickNavigation;
